<template>
  <el-row class="warp">
    <el-col :span="24">
      <!--工具条-->
      <el-form :inline="true" :model="searchForm" size="small">
        <el-form-item>
          <el-input v-model="searchForm.createUserName" placeholder="提交人姓名" @keyup.enter.native="handleSearch"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="searchForm.content" placeholder="意见反馈内容" @keyup.enter.native="handleSearch"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询 </el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleReset">重置 </el-button>
        </el-form-item>
      </el-form>
      <!--列表-->
      <el-table :data="listData">
        <el-table-column type="index" label="序号" width="60"></el-table-column>
        <el-table-column prop="createUserName" label="提交人" width="120"></el-table-column>
        <el-table-column prop="mobile" label="提交人手机号"></el-table-column>
        <el-table-column prop="content" label="意见反馈内容" width="300"></el-table-column>
        <el-table-column prop="createTime" label="提交时间" width="160"></el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button size="small" @click="deleteSubmit(scope.row.sugId)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!--工具条-->
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="10"
        :page-sizes="[10, 20, 30, 50]"
        :total="total"
        style="float:right;"
      >
      </el-pagination>
    </el-col>
  </el-row>
</template>
<script>
import API from '@/api/system/api_suggestion'
import _ from 'lodash'
import PagesMixins from '@/mixins/pagesMixins'
let searchForm = {
  createUserName: '',
  content: ''
}
export default {
  mixins: [PagesMixins],
  data() {
    return {
      searchForm: _.cloneDeep(searchForm),
      listData: []
    }
  },
  mounted() {
    this.handleSearch()
  },
  methods: {
    handleSearch() {
      this.total = 0
      this.page = 1
      this.getListData()
    },
    handleReset() {
      this.$nextTick(() => {
        this.searchForm = _.cloneDeep(searchForm)
        this.handleSearch()
      })
    },
    getListData() {
      let params = {
        page: this.page,
        size: this.size,
        ...this.searchForm
      }
      API.list(params).then(result => {
        if (result && result.code === '000000') {
          this.total = result.data.total
          this.listData = result.data.list
        }
      })
    },
    deleteSubmit(id) {
      this.$confirm('确认要删除吗?', '提示', {
        confirmButtonClass: 'el-button--warning'
      }).then(() => {
        API.del(id).then(res => {
          if (res && res.code === '000000') {
            this.$message.success({ message: res.msg || '删除成功', duration: 2000 })
            this.getListData()
          } else {
            this.$message.error({ message: res.msg, duration: 2000 })
          }
        })
      })
    }
  }
}
</script>
