import * as http from '../http'

export default {
  //列表
  list: params => {
    return http.post('/suggestion/pageList', params)
  },
  //删除
  del: id => {
    return http.post(`/suggestion/delete/${id}`)
  }
}
